export default function Logo(props) {
  return (
    <svg
      {...props}
      width="100%"
      height="100%"
      viewBox="0 0 132 133"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
    >
      <g >
        <circle cx="66.288" cy="65.962" r="63.5" style={{ fill: "#2961a4" }} />
        <path
          d="M80.876,36.209l-0.141,0.104c-0.687,-0.069 -1.515,-0.104 -2.359,-0.104l-20,0c-2.578,0 -5.078,0.325 -7.469,0.938c-0.015,-0.313 -0.031,-0.622 -0.031,-0.938c0,-8.284 6.578,-15 15,-15c8.281,0 15,6.716 15,15Zm-2.5,5c0.547,0 0.953,0.016 1.625,0.047c0.672,0.047 1.328,0.11 1.969,0.203c2.75,-3.203 6.844,-5.25 11.406,-5.25l5,0l-2.937,11.735c2.484,2.312 4.484,5.125 5.859,8.265l2.078,0c2.766,0 5,2.235 5,5l0,15c0,2.766 -2.234,5 -5,5l-5,0c-1.422,1.891 -3.109,3.579 -5,5l0,10c0,2.766 -2.234,5 -5,5l-5,0c-2.766,0 -5,-2.234 -5,-5l0,-5l-20,0l0,5c0,2.766 -2.234,5 -5,5l-5,0c-2.766,0 -5,-2.234 -5,-5l0,-10c-5.453,-4.093 -9.172,-10.343 -9.876,-17.5l-4.499,0c-5.869,0 -10.625,-4.75 -10.625,-10.625c0,-5.875 4.756,-10.625 10.625,-10.625l0.625,0c2.07,0 3.75,1.672 3.75,3.75c0,2.079 -1.68,3.75 -3.75,3.75l-0.625,0c-1.726,0 -3.125,1.266 -3.125,3.125c0,1.719 1.399,3.125 3.125,3.125l4.875,0c1.891,-9.343 9.016,-16.796 18.172,-19.187c2.016,-0.531 4,-0.813 6.328,-0.813l20,0Zm6.25,17.5c-2.078,0 -3.75,1.672 -3.75,3.75c0,2.079 1.672,3.75 3.75,3.75c2.078,0 3.75,-1.671 3.75,-3.75c0,-2.078 -1.672,-3.75 -3.75,-3.75Z"
          style={{ fill: "#fff", fillRule: "nonzero" }}
        />
      </g>
    </svg>
  );
}
