import React from "react"
import LoginOrProfile from "./LoginOrProfile"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import Logo from './BoardLogo'

import { Disclosure } from "@headlessui/react"
import { X as XIcon } from "@styled-icons/heroicons-outline/X"
import { Menu as MenuIcon } from "@styled-icons/heroicons-outline/Menu"

const NavLink = React.forwardRef((props,ref) => (
  <a
    {...props}
    ref={ref}
    className={`px-4 py-2 rounded-full hover:bg-blue-50  ${
      props.current ? "text-blue-800 bg-blue-50" : ""
    }
    ${props.highlight ? "text-white bg-gradient-to-r from-blue-900 to-blue-700 hover:ring-2 hover:ring-blue-300" : "hover:text-blue-800"}
    ${!props.highlight && !props.current ? "text-gray-600" : ""}}`}
  >
    {props.children}
  </a>
))

const Button = React.forwardRef((props,ref) => (
  <button
    {...props}
    ref={ref}
    className="inline-flex items-center justify-center p-2 rounded-full text-blue-800 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-200"
  >
    {props.children}
  </button>
))

export default function NavBar() {
  const router = useRouter()
  const { t } = useTranslation()

  const navigation = [
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }

  return (
    <Disclosure
      as="nav"
      className="sticky top-0 z-20 bg-white/80 backdrop-blur-md"
    >
      {({ open }) => (
        <div>
          <div className="mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              {/* <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button as={Button}>
                  <div className="">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </div>
                </Disclosure.Button>
              </div> */}
              <div className="flex items-center justify-center sm:items-stretch sm:justify-start">
                  <a href="/" className="flex-grow-0 sm:flex gap-4 sm:items-center" title="Bank Statement">
                    <Logo className="h-9 w-9 sm:h-8" /> 
                  </a>
                  <a href="/"><span className="uppercase text-lg font-bold ml-2">Bank Statement Converter</span></a>
                <div className="hidden sm:flex sm:items-center  sm:ml-6">
                  <div className="flex gap-1 text-md">
                    {navigation
                      .filter(n => !n.mobileOnly)
                      .map(({ name, href, current, highlight, onClick }) => (
                          <NavLink
                            key={name}
                            href={href}
                            current={router.asPath === href ? "true" : undefined}
                            aria-current={
                              router.asPath === href ? "page" : undefined
                            }
                            highlight={highlight ? "true" : undefined}
                            onClick={onClick}
                          >
                            {name}
                          </NavLink>
                      ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <LoginOrProfile />
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-2">
              {navigation.map(({ name, href, highlight }) => (
                  <a
                    href={href} 
                    key={name}
                    className={`block  hover:bg-blue-100 hover:text-blue-800 px-4 py-2 rounded-full disabled:opacity-50 ${
                      highlight
                        ? "text-blue-100 bg-blue-900"
                        : "text-blue-800 bg-blue-50"
                    }`}
                    aria-current={router.asPath === href ? "page" : undefined}
                  >
                    {name}
                  </a>
              ))}
            </div>
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
}
