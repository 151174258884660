import firebase from "firebase/app"

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyD6gtO-8o3mlFsCxzMppuBEFRVCTba8Nwc",
    authDomain: "bankstatement-60c29.firebaseapp.com",
    projectId: "bankstatement-60c29",
    storageBucket: "bankstatement-60c29.appspot.com",
    messagingSenderId: "845711878354",
    appId: "1:845711878354:web:7d8b2df9317fe26959a50b",
    measurementId: "G-SS2ZYBN5XV"
  })

  const setLocalhost = async () => {
    await import("firebase/auth")
    firebase.auth().useEmulator("http://localhost:9099/", { disableWarnings: true })
    await importFirestore()
    firebase.firestore().useEmulator("localhost", 8080)
    await import("firebase/functions")
    firebase.functions().useEmulator("localhost", 5001)
    await importStorage()
    firebase.storage().useEmulator("localhost", 9199)
  }
  if (process.env.NEXT_PUBLIC_VERCEL_URL.includes("localhost")) {
    setLocalhost()
  }

}
export default firebase

const importFirestore = async () => {
    await import("firebase/firestore")
}
const importStorage = async () => {
  await import("firebase/storage")
}

const boards = async () => {
    await importFirestore()
    return firebase.firestore().collection(`boards`)
}
const users = async () => {
    await importFirestore()
    return firebase.firestore().collection(`users`)
}
const customers = async () => {
  await importFirestore()
  return firebase.firestore().collection(`customers`)
}
const productImages = async () => {
    await importStorage()
    const storageRef = firebase.storage().ref()
    storageRef.child('product/images')
    return storageRef.child('product/images')
}
const boardImages = async () => {
    await importStorage()
    const storageRef = firebase.storage().ref()
    storageRef.child('product/images')
    return storageRef.child('board/images')
}
const userInputImages = async () => {
  await importStorage()
  const storageRef = firebase.storage().ref()
  return storageRef.child('uploads/input-images')
}
const profilePhotos = async () => {
  await importStorage()
  const storageRef = firebase.storage().ref()
  return storageRef.child('profile/photos')
}
const aiGenerations = async () => {
  await importFirestore()
  return firebase.firestore().collection(`ai-generator`)
}

const loadBoardImageUrl = async boardId => {
  const boardImagesRef = await boardImages()
  const image = boardImagesRef.child(`${boardId}.jpg`)
  try {
     const imageUrl = await image.getDownloadURL()
     return imageUrl
  } catch(e) {
    return null
  }
}

const loadUserPublicDetailsFromUid = async uid => {
  const userRef = await users()
  const usersRes = await userRef.where("uid", "==", uid).get()
  if(usersRes.empty) return

  const doc = usersRes.docs[0]
  const username = doc.id
  const userPublicData = doc.data()
  const userPublic = {username, ...userPublicData}
  
  console.log("user data loaded: ", userPublicData)

  return userPublic
}

const loadUserPublicDetailsFromUsername = async username => {
  const usersDb = await users()
  const userRes = await usersDb.doc(username).get()
  if(!userRes.exists) return "not found"

  const userPublicData = userRes.data()

  return {username, ...userPublicData}
}
export { boards, users, customers, productImages, boardImages, loadBoardImageUrl, loadUserPublicDetailsFromUsername, loadUserPublicDetailsFromUid, profilePhotos, aiGenerations, userInputImages }