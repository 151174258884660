import React, { useContext, useState, useEffect } from 'react';
import { FirebaseContext } from './Firebase'

import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import Link from "next/link"
import { Home } from "@styled-icons/feather/Home"

const LoginOrProfile = (props) => {
    const router = useRouter()
    const auth = useContext(FirebaseContext)
    const { user } = auth
    const NotificationDropdown = dynamic(() => import("react-activity-feed").then(mod => mod.NotificationDropdown))
    const ProfileThumb = dynamic(() => import("./ProfileThumb"))
    const [userPublic, setUserPublic] = useState(null)

    useEffect(() => {
      if (localStorage.getItem("userPublic")) {
        setUserPublic(JSON.parse(localStorage.getItem("userPublic")))
      }
    }, [])

    useEffect(() => {
      if (auth.userPublic === null) return

      setUserPublic(auth.userPublic)
    }, [auth.userPublic])

    if(user === null && !userPublic) return <></>

    return (
      <>
        {(!user || user.isAnonymous) && !userPublic ? (
           <Link href="/profile" passHref prefetch={false}>
            <a className="my-3 mx-4 px-4 py-2 bg-blue-100 text-blue-800 rounded-full focus:outline-none hover:ring-2 hover:ring-blue-200">
              Login
            </a>
            </Link>
        ) : (
          <div className="flex items-center">
            <div className='w-10'>
                <ProfileThumb userPublic={userPublic} linkToAccountAdmin />
            </div>
          </div>
        )}
      </>
    )
}
export default LoginOrProfile