import React from "react"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import Link from "next/link"
import { PlayCircleFill as PlayCircle } from '@styled-icons/bootstrap/PlayCircleFill'

const NavLink = React.forwardRef((props,ref) => (
  <a {...props} ref={ref} className="py-1 rounded-full text-blue-700 hover:text-blue-800">
    {props.children}
  </a>
))

export default function Footer() {
  const router = useRouter()
  const { t } = useTranslation()

  const videoId = "VwyDB53n2Nw"

  const siteUrl = process.env.NEXT_PUBLIC_SITEURL

  return (
    <footer className="text-gray-600">
      <div className="container max-w-screen-lg px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
        {/* <div className="w-full md:w-1/3  px-4 mb-12 text-center">
          <h2 className="font-medium text-gray-900 tracking-widest text-sm mb-3 uppercase">
            From Our Founder
          </h2>
          <div className="relative aspect-w-16 aspect-h-9 shadow-lg rounded-xl group">
            <a href={`https://www.youtube.com/watch?v=${videoId}`} className="absolute top-0 left-0 w-full h-full" target="_blank">
              <img 
                src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`} 
                alt="Marta's AI-Powered Interior Design for Relaxation and Inspiration" 
                className="absolute top-0 left-0 w-full h-full rounded-lg group-hover:opacity-80 object-cover" 
                loading="lazy"
              />
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                <PlayCircle size="65" className="text-blue-700 bg-white rounded-full "  />
              </div>
            </a>
          </div>
          <p className="mt-4 text-sm text-left">Check out Marta's video on AI-Powered Interior Design for Relaxation and Inspiration! 🌱🛋️</p>
        </div> */}
      
        <div className="flex-grow flex flex-wrap md:pl-10 -mb-10 md:mt-0 mt-10 md:text-left text-center">
          {/* <div className="md:w-3/4 w-full px-4">
            <h2 className="font-medium text-gray-900 tracking-widest text-sm mb-3 uppercase">
            <Link href="/blog" passHref>
              <a>Blog</a>
            </Link>
            </h2>
            <nav className="mb-10 flex flex-col">
              <Link href={`${siteUrl}/blog/posts/moodboardly-the-best-mood-board-creator`} passHref>
                <NavLink>The Best Mood Board Creator</NavLink>
              </Link>
              <Link href={`${siteUrl}/blog/posts/designing-your-dream-kitchen-with-ai`} passHref>
                <NavLink>Designing Your Dream Kitchen With AI</NavLink>
              </Link>
              <Link href={`${siteUrl}/blog/posts/designing-a-room-with-the-power-of-ai`} passHref>
                <NavLink>Designing A Room With The Power Of AI</NavLink>
              </Link>
              <Link href={`${siteUrl}/blog/posts/mood-board-creation-with-ai`} passHref>
                <NavLink>Mood Board Creation With AI</NavLink>
              </Link>
              <Link href={`${siteUrl}/how-to-create-a-mood-board`} passHref>
                <NavLink>{t("index.how-to-create-mood-board")}</NavLink>
              </Link>
              <Link href={`${siteUrl}/the-3-best-ways-to-find-interior-design-inspiration`} passHref>
                <NavLink>{t("index.the-3-best-ways")}</NavLink>
              </Link>
            </nav>
          </div> */}
          <div className="md:w-1/4 w-full px-4">
            <h2 className="font-medium text-gray-900 tracking-widest text-sm mb-3 uppercase">
              About
            </h2>
            <nav className="mb-10 flex flex-col">
              <Link href="/pricing" passHref>
                <NavLink>{t("nav.pricing")}</NavLink>
              </Link>
              <Link href="/privacy" passHref>
                <NavLink>{t("privacy")}</NavLink>
              </Link>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  )
}
